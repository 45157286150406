import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/copyrightRegistration/HomeView.vue'
import Index from '../views/index.vue'
// import introduce from '../views/introduce.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  // console.log("location",location)
  return originalPush.call(this, location).catch(err => err)
}
//解决vue路由重复导航错误
const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '/',
        name: 'introduce',
        component: () => import('../views/introduce/introduce.vue')
      },
      {
        path: '/stagePlay',
        name: 'introducePlay',
        component: () => import('../views/introduce/introducePlay.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/newsList',
        name: 'newsList',
        component: () => import('../views/introduce/newsList.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/information',
        name: 'information',
        component: () => import('../views/introduce/information.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/asseTlist',
        name: 'asseTlist',
        component: () => import('../views/asset/index.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/asseTdetalis',
        name: 'asseTdetalis',
        component: () => import('../views/asset/asseTdetalis.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/orderDetails',
        name: 'orderDetails',
        component: () => import('../views/asset/orderDetails.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/payDetails',
        name: 'payDetails',
        component: () => import('../views/asset/payDetails.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/payResult',
        name: 'payResult',
        component: () => import('../views/asset/payResult.vue'),
        meta: { shovalues: '1' }
      },
      {
        path: '/transaction',
        name: 'transaction',
        component: () => import('../views/asset/transaction.vue'),
        meta: { shovalues: '1' }
      },
      // {
      //   path: '/home',
      //   name: 'home',
      //   component: HomeView
      // },
      {
        path: '/copyright',
        component: () => import('../views/copyrightRegistration/index.vue'),
        children: [{
          path: 'home',
          component: () => import('../views/copyrightRegistration/HomeView.vue'),
        },{
          path: 'password',
          component: () => import('../views/copyrightRegistration/password.vue'),
        },{
          path: 'government',
          component: () => import('../views/copyrightRegistration/government.vue'),
        }]
      }, 
      {
        path: '/copyrightRegistration',
        name: 'copyrightRegistration',
        component: () => import('../views/copyrightRegistration/AboutView.vue')
      },
      {
        path: '/registerList',
        name: 'registerList',
        component: () => import('../views/register/registerList.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/register/register.vue')
      },
      {
        path: '/registerGoods',
        name: 'registerGoods',
        component: () => import('../views/register/registerGoods.vue')
      },
      {
        path: '/transferenceList',
        name: 'transferenceList',
        component: () => import('../views/transference/transferenceList.vue')
      },
      {
        path: '/transference',
        name: 'transference',
        component: () => import('../views/transference/transference.vue')
      },
      {
        path: '/transferenceGoods',
        name: 'transferenceGoods',
        component: () => import('../views/transference/transferenceGoods.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue')
      },
      {
        path: '/logon',
        name: 'logon',
        component: () => import('../views/login/register.vue')
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('../views/myAccount/index.vue')
      },
      {
        path: '/addBank',
        name: 'addBank',
        component: () => import('../views/myAccount/addBank.vue')
      },
      {
        path: '/addComBank',
        name: 'addComBank',
        component: () => import('../views/myAccount/addComBank.vue')
      },
      {
        path: '/oderGoods',
        name: 'oderGoods',
        component: () => import('../views/myAccount/oderGoods.vue')
      },
      {
        path: '/addCard',
        name: 'addCard',
        component: () => import('../views/myAccount/addCard/index.vue')
      },
      {
        path: '/exitEvidence',
        name: 'exitEvidence',
        component: () => import('../views/preservaTion/introduce.vue')
      },
      {
        path: '/preservaTion',
        name: 'preservaTion',
        component: () => import('../views/preservaTion/index.vue')
      },
      {
        path: '/certificate',
        name: 'certificate',
        component: () => import('../views/preservaTion/certificate.vue')
      },
      {
        path: '/assetIssuance',
        component: () => import('../views/assetIssuance/index.vue'),
        children: [{
          path: '',
          component: () => import('../views/assetIssuance/assetIssue.vue'),
        }, {
          path: 'newAssetCreate',
          component: () => import('../views/assetIssuance/newAssetCreate.vue'),
        }, {
          path: 'addEdition',
          component: () => import('../views/assetIssuance/addEdition.vue'),
        }, {
          path: 'orderList',
          component: () => import('../views/assetIssuance/orderList/index.vue'),
        }, {
          path: 'shelfList',
          component: () => import('../views/assetIssuance/shelfList/shelfList.vue'),
        }, {
          path: 'shelfDetails',
          component: () => import('@/views/assetIssuance/shelfList/children/shelfDetails')
        },  {
          path: 'editShelf',
          component: () => import('@/views/assetIssuance/shelfList/children/editShelf')
        }, {
          path: 'theshelfList',
          component: () => import('@/views/assetIssuance/shelfList/children/theshelfList')
        }, {
          path: 'assetCommodity',
          component: () => import('@/views/assetIssuance/assetCommodity/index.vue')
        }, {
          path: 'exercise',
          component: () => import('@/views/assetIssuance/exercise.vue')
        }, {
          path: 'orderExercise',
          component: () => import('@/views/assetIssuance/orderList/orderList.vue')
        }, {
          path: 'exerciseList',
          component: () => import('@/views/assetIssuance/exerciseList.vue')
        }, {
          path: 'order',
          component: () => import('@/views/assetIssuance/order')
        }, {
          path: 'orderDetails',
          component: () => import('@/views/assetIssuance/order/details.vue')
        }]
      },
      {
        path: '/orderListDetails',
        component: () => import('../views/assetIssuance/order/details.vue'),
      },
      {
        path: '/exerciseDetails',
        component: () => import('@/views/assetIssuance/exerciseDetails.vue')
      },{
        path: '/assetManagement',
        component: () => import('../views/assetManagement/index.vue'),
        children: [{
          path: 'assetsCopyright',
          component: () => import('../views/assetManagement/assetsCopyright.vue'),
        }, {
          path: 'assetsRealRight',
          component: () => import('../views/assetManagement/assetsRealRight.vue'),
        }, {
          path: 'copyrightRegister',
          component: () => import('../views/assetManagement/copyrightRegister.vue'),
        }, {
          path: 'authorizationAssets',
          component: () => import('../views/assetManagement/authorizationAssets.vue'),
        }, {
          path: 'transferAssets',
          component: () => import('../views/assetManagement/transferAssets.vue'),
        }, {
          path: 'workRegistration',
          component: () => import('../views/assetManagement/workRegistration.vue'),
        }, 
        { //资产管理-资产详情
          path: 'assetsDetail',
          component: () =>  import('../views/assetManagement/children/assetsDetail.vue'),
        },
        // {//资产管理-著作权注册资产详情
        //   path: 'copyrightDetail',
        //   component: () =>  import('../views/assetManagement/children/registerDetail'),
        // },
        // {
        //   path: 'orderList',
        //   component: () => import('../views/assetManagement/orderList/index.vue'),
        // },{
        //   path: 'shelfList',
        //   component: () => import('../views/assetManagement/shelfList/shelfList.vue'),
        // }, {
        //   path: 'shelfDetails',
        //   component: () => import('@/views/assetManagement/shelfList/children/shelfDetails')
        // }, {
        //   path: 'assetCommodity',
        //   component: () => import('@/views/assetManagement/assetCommodity/index.vue')
        // }
      ]
      },{
        path: '/copyrightDetail',
        name: 'copyrightDetail',
        component: () => import('../views/assetManagement/children/copyrightDetail.vue')
      },{
        path: '/verification',
        name: 'verification',
        component: () => import('../views/assetManagement/children/verification.vue')
      },{
        path: '/egistrationDetails',
        name: 'egistrationDetails',
        component: () => import('../views/assetManagement/children/egistrationDetails.vue')
      },{
        path: '/nodeAccess',
        name: 'nodeAccess',
        component: () => import('../views/nodeAccess/index.vue'),
      },{
        path: '*',
        component: () => import('../views/404.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
router.afterEach(function (to) {
  window.scrollTo(0, 0)
 })
export default router
